import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';

const geoUrl =
  'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

// eslint-disable-next-line react/display-name
const MapChart = ({
  region: currentRegion,
  setRegion,
  markers,
  setTooltipContent,
  content,
  lang, // language setting
}) => {
  // const intl = useIntl();
  return (
    <ComposableMap
      projectionConfig={{ scale: 180 }}
    // projection="geoAzimuthalEqualArea"
    // projectionConfig={{
    //   rotate: [58.0, 20.0],
    //   scale: 400,
    // }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies
            // .filter(d => d.properties.REGION_UN === 'Americas')
            .map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
        }
      </Geographies>
      {markers.map(({ region, name, coordinates, markerOffset }, index) =>
        region === currentRegion ? (
          <Marker key={name} coordinates={coordinates}>
            <circle r={10} fill="#0F0" stroke="#F00" strokeWidth={2} />
            <text
              textAnchor="middle"
              y={markerOffset + 5}
              style={{
                fontSize: '20',
                fill: '#5D5A6D',
              }}
            >
              {name}
              {/* <FormattedMessage
                id={`region.displayName.${name}.${lang}`}
                defaultMessage={name}
              /> */}
              {/* {intl.formatMessage({
                id: ``,
                defaultMessage: name,
              })} */}
            </text>
          </Marker>
        ) : (
          <Marker
            key={name}
            coordinates={coordinates}
            onClick={() => {
              setRegion(name);
            }}
            onMouseEnter={() => {
              setTooltipContent(`${name}`);
            }}
            onMouseLeave={() => {
              setTooltipContent('');
            }}
          >
            <circle r={6} fill="#F00" stroke="#fff" strokeWidth={2} />
            {content === name && (
              <text
                textAnchor="middle"
                y={markerOffset - 5}
                style={{
                  fontSize: '15',
                  fill: '#5D5A6D',
                }}
              >
                {name}
              </text>
            )}
          </Marker>
        )
      )}
    </ComposableMap>
  );
};

export default MapChart;
