import React, { useState, useEffect } from 'react';
import { navigate, FormattedMessage } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { connect } from 'react-redux';
import {
  getRegionStart,
  setRegion,
} from '@allconnect/store/lib/user/user.actions';
import { createStructuredSelector } from 'reselect';
import Button from 'components/CustomButtons';

import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';

import { Regions } from '@allconnect/models/lib/regions';
import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import {
  selectRegion,
  selectCurrentUser,
} from '@allconnect/store/lib/user/user.selectors';
import MapChart from './MapChart';

const regionMarkers = Regions.allMarkers();

const useStyle = makeStyles(productStyle);

const ServerLocation = ({
  currentRegion,
  setRegion,
  currentLang,
  currentUser,
}) => {
  const classes = useStyle();
  // tooltip content
  const [content, setContent] = useState('');
  // closest region's City Name
  const [city, setCity] = useState('');

  useEffect(() => {
    if (currentRegion && regionMarkers) {
      regionMarkers.map(region => {
        if (region.region === currentRegion) {
          setCity(region.name);
        }
      });
    }
  }, [currentRegion]);

  useEffect(() => {
    getRegionStart();
  }, []);

  return (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>
              <FormattedMessage
                id="server.title"
                defaultMessage="Network Location Awareness"
              />
            </h2>
            <h5 className={classes.description}>
              <FormattedMessage
                id="server.subtitle"
                defaultMessage="{city} is the closest region to you"
                values={{
                  city,
                }}
              />
            </h5>
            <Button
              round
              color="google"
              onClick={() => {
                if (currentUser) {
                  open('https://heidi.cloud/hi-vpn');
                } else {
                  open('https://heidi.cloud/hi-vpn');
                }
              }}
            >
              {currentUser ? (
                <FormattedMessage
                  id="landing.MyAccount"
                  defaultMessage="My Account"
                />
              ) : (
                <FormattedMessage
                  id="server.location.button"
                  defaultMessage="Get Free Access"
                />
              )}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.serverSection}>
        <MapChart
          region={currentRegion}
          setRegion={setRegion}
          markers={regionMarkers}
          content={content}
          setTooltipContent={setContent}
          lang={currentLang}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentRegion: selectRegion,
  currnetLang: selectLang,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
  getRegionStart: () => dispatch(getRegionStart()),
  setRegion: city => {
    regionMarkers.map(region => {
      if (region.name === city) {
        dispatch(setRegion(region.region));
      }
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ServerLocation);
