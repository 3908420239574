import { title } from 'assets/jss/material-kit-react.jsx';

const productStyle = theme => {
  const {
    palette: { type, text, background, primary },
  } = theme;
  return {
    container: {
      display: 'flex',
      padding: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    section: {
      padding: '30px 0',
      textAlign: 'center',
    },
    serverSection: {
      padding: '0',
      textAlign: 'center',
    },
    divider: {
      marginBottom: '20px',
      marginTop: '10px',
    },
    footer: {
      justifyContent: 'center',
    },
    title: {
      ...title,
      color: text.primary,
      marginBottom: '1rem',
      marginTop: '1rem',
      minHeight: '32px',
      textDecoration: 'none',
    },
    description: {
      color: text.primary,
    },
    price: {
      color: text.primary,
      fontWeight: 700,
      fontSize: '2rem',
    },
  };
};

export default productStyle;
